import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  blocksWithoutTabContent,
  isAnnouncementStrip,
  isBanner,
  isNavbar,
  isPrimaryContact,
  isRecommendation,
  isTitle,
  primaryContact,
  recommendation,
  title,
} from "utils/renderBlocks";

import { Container, Grid } from "components/Design/Grid/Grid";
import { RenderBlock } from "components/RenderBlock";
import { Pricing, PricingItem } from "./Design/Card/Pricing";

export const ExperienceLayout = ({ blocks, activity, isMobile }) => {
  const { t } = useTranslation(["homepage"]);
  const recommendationBlock = recommendation(blocks);
  const primaryContactBlock = primaryContact(blocks);
  const titleBlock = title(blocks);

  const { adult_price, child_price } = activity;

  return (
    <div className="mb-24 lg:mb-60">
      <Container>
        <Grid>
          <div className="col-span-12 lg:col-span-7 mt-24 lg:mt-60 flex flex-col gap-30 lg:gap-40">
            <h1 className="font-semibold text-h4 md:text-h3 text-gray-text">
              {titleBlock.title}
            </h1>
            <div className="lg:hidden lg:flex lg:flex-col lg:gap-20 lg:col-span-5 lg:mt-60">
              <Pricing>
                <PricingItem label={t("AdultWithoutCount")} price={adult_price} />
                <PricingItem label={t("ChildWithoutCount")} price={child_price} />
              </Pricing>
              {primaryContactBlock && (
                <RenderBlock block={primaryContactBlock} blocks={blocks} />
              )}
            </div>

            {blocksWithoutTabContent(blocks).map((b) => {
              const skip =
                isTitle(b) ||
                isNavbar(b) ||
                isBanner(b) ||
                isRecommendation(b) ||
                isPrimaryContact(b) ||
                isAnnouncementStrip(b);

              if (!skip) {
                return (
                  <RenderBlock
                    key={`${b.__typename}:${b.id}`}
                    block={b}
                    isMobile={isMobile}
                    blocks={blocks}
                  />
                );
              }
            })}
          </div>
          <div className="hidden lg:flex lg:flex-col lg:gap-20 lg:col-span-5 ml-20 lg:mt-60">
            <Pricing>
              <PricingItem label={t("AdultWithoutCount")} price={adult_price} />
              <PricingItem label={t("ChildWithoutCount")} price={child_price} />
            </Pricing>
            {primaryContactBlock && (
              <RenderBlock block={primaryContactBlock} blocks={blocks} />
            )}
          </div>
          {recommendationBlock && (
            <div className="col-span-12">
              <RenderBlock
                key={`recommendation-${recommendationBlock.id}`}
                block={recommendationBlock}
              />
            </div>
          )}
        </Grid>
      </Container>
    </div>
  );
};
